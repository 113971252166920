<template>
  <div>
    <div class="row align-items-stratch">
      <div class="col-3 my-3 h-100">
        <button
          @click="onFileChange"
          class="h-100 btn border border-3 rounded border-secondary w-100 d-flex justify-content-center align-items-center"
          :style="{
            borderStyle: 'dashed !important',
          }"
        >
          <img
            v-show="imageUrl"
            :src="imageUrl"
            alt=""
            id="imagePreview"
            class="w-100 h-100"
          />
          <div v-show="!imageUrl" id="imagePreview" class="h-100">
            <p>Importer ici l'image de votre produit</p>
          </div>
        </button>
      </div>

      <div class="col-9 row align-items-end">
        <div class="col-4 my-3">
          <label for="">Catégorie</label>
          <select class="form-select" v-model="item.section_reference">
            <option
              v-for="(section, index) in sections"
              :key="index++"
              :value="section.reference"
            >
              {{ section.fullName }}
            </option>
          </select>
        </div>

        <div class="col-8 my-3">
          <label for="">Désignation</label>
          <input type="text" v-model="item.fullName" class="form-control" />
        </div>

        <div class="col my-3">
          <label for=""> Prix TTC</label>
          <input type="text" v-model="item.priceTTC" class="form-control" />
        </div>

        <div class="col my-3">
          <label for="">Unité </label>
          <select class="form-select" v-model="item.measuring">
            <option value="KG">KG</option>
            <option value="Pc">Pièce</option>
            <option value="L">Litre</option>
            <option value="M">Mètre</option>
          </select>
        </div>

        <div class="col my-3">
          <label for=""> TVA </label>
          <select class="form-select" v-model="item.tva">
            <option value="0" :selected="true">0 %</option>
            <option value="20">20 %</option>
            <option value="15">15 %</option>
            <option value="10">10 %</option>
            <option value="7">7 %</option>
            <option value="5">5 %</option>
            <option value="2.5">2.5 %</option>
          </select>
        </div>

        <div class="col-12 my-3">
          <label for="">Description</label>
          <textarea v-model="item.description" class="form-control"></textarea>
        </div>
      </div>
    </div>

    <br />
    <div class="row justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(item)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      item: {},
      file: null,
      imageUrl: "",
    };
  },
  computed: {
    ...mapGetters("section", {
      sections: "getAll",
    }),
  },
  methods: {
    async save(data) {
      let formData = new FormData();

      formData.append("image", this.file);
      formData.append("fullName", data.fullName);
      if (data.description) formData.append("description", data.description);
      if (data.tva) formData.append("tva", data.tva);
      if (data.priceTTC) formData.append("priceTTC", data.priceTTC);
      if (data.measuring) formData.append("measuring", data.measuring);
      formData.append("section_reference", data.section_reference);

      await this.$store.dispatch("item/store", formData);

      // await this.$store.dispatch("item/store", data);
      await setInterval((this.item = {}), 1000);
    },

    async onFileChange(event) {
      // this.file = event.target.files[0];
      const input = document.createElement("input");
      input.type = "file";
      input.style.display = "none";

      input.addEventListener("change", (event) => {
        this.file = event.target.files[0];

        // Show the image
        this.imageUrl = URL.createObjectURL(this.file);
      });

      document.body.appendChild(input);
      input.click();
      document.body.removeChild(input);
      // Show the image
      this.imageUrl = URL.createObjectURL(this.file);
    },
  },
  beforeMount() {
    this.$store.dispatch("section/getAll");
  },
};
</script>
